@import "../../../../../styles.scss";

.buttonContainer {
  background: #000;
}

.confirmButton,
.deleteButton,
.neutralButton {
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 10rem;
}

.confirmButton {
  color: white;
  background: $primary-button-color;
  transition: 0.3s;
}

.confirmButton:hover {
  color: white;
  background: rgb(5, 0, 150);
}

.deleteButton {
  color: white;
  background: $secondary-button-color;
}

.deleteButton:hover {
  color: white;
  background: rgb(150, 20, 20);
}
