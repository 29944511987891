@import "../../styles.scss";
.inputWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.label {
  font-size: 14px;
  margin-bottom: 14px;
  color: $secondary-text;
}

.input {
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid $white;
  border-radius: 4px;
  outline: none;
  width: 280px;
  height: 35px;
  transition: border-color 0.3s;
}

.input:focus {
  border-color: $primary-button-color;
}

.error {
  border-color: $secondary-button-color;
}

.errorMessage {
  font-size: 12px;
  color: $secondary-button-color;
  margin-top: 4px;
}
