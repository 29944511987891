@import "../../../../../styles.scss";
$padding: 1rem;

.modalBackground {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 800;
}

.modalContainer {
  max-width: 700px;
  width: 90vw;
  max-height: 90vh;
  background-color: white;
  border: 2px solid #f7f7f7;
  border-radius: 14px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 999;
}

.modalHeader {
  padding: 0.5rem $padding;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 2px solid #f7f7f7;
  flex-shrink: 0;
}

.modalContent {
  flex: 1;
  overflow-y: auto;
  padding: 0 $padding;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
}

.actionContainer {
  padding: 10px $padding;
  flex-shrink: 0;
  border-top: 2px solid #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-weight: 600;
}

.description {
  font-size: 14px;
  padding: 8px 0 0 0;
  color: grey;
}
