//Color palette univarsal variables
$logo: #fefc04;
$logo-grey: #adadad;
$primary-text: #0d0e11;
$secondary-text: #575b68;
$primary-background: #eaebf1;
$primary-button-color: #3036ba;
$secondary-button-color: #ab2913;
$primary-border: #d8d8d8;
$white: #fff;

@mixin centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.comingSoonContainer {
  width: 100vw;
  height: 100vh;
}

.splashScreenContainer {
  @include centered;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  background-color: $white;
  position: fixed;
  top: 0;
  left: 0;
}

.logo {
  height: 6rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: $logo;
}

.companyName {
  font-size: 6rem;
  padding: 1rem 0;
  display: flex;
}

.logoStrong,
.logoGrey {
  font-weight: 600;
  font-family: futura-medium-condensed;
}

.logoGrey {
  color: $logo-grey;
}

.companyLocation {
  font-size: 1.3rem;
  overflow: hidden;
}
.animatedWrapper {
  position: relative; /* Ensures the span can be positioned relative to it */
  height: 40; /* Ensures no space is taken by the wrapper */
  overflow: hidden; /* Hides the content that is off-screen */
}

.animatedWrapper span {
  display: inline-block; /* Ensures span can be animated */
  left: 0;
  top: 0; /* This makes sure the span starts from the top of the container */
}

.logoContainer {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3rem 0;
}

.comingSoonImage {
  height: 9rem;
}

.comingSoonMessageConrainer {
  padding: 1rem;
  @include centered;
  flex-direction: column;
}

.comingSoonTitle {
  font-size: 2.5rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
}

.comingSoonSubtitle {
  color: $secondary-text;
}

.comingsoonLlogo {
  width: 4rem;
  aspect-ratio: 1/1;
  background-color: $logo;
  border-radius: 50%;
}

.comingsoonCompanyName {
  font-size: 2rem;
  display: flex;
  padding: 1rem;
}

.ComingSoonFooter {
  width: 100vw;
  box-sizing: border-box;
  padding: 1rem;
  position: fixed;
  bottom: 0;
}

.languageSwitch {
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  box-sizing: border-box;
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
}

.languageButton {
  cursor: pointer;
}

.languageToggle {
  display: flex;
  cursor: pointer;
}

.ComingSoonFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.9rem;
}
.comingSoonFooterSection-start,
.comingSoonFooterSection-center,
.comingSoonFooterSection-end {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 33%;
}

.comingSoonFooterSection-start {
  align-items: start;
}
.comingSoonFooterSection-center {
  align-items: center;
}
.comingSoonFooterSection-end {
  align-items: end;
}

.center {
  text-align: center;
}

@media (min-width: 1600px) {
  .comingsoonLlogo {
    width: 6rem;
  }
  .comingsoonCompanyName {
    font-size: 3rem;
  }
  .comingSoonImage {
    height: 15rem;
  }
  .logoContainer {
    padding: 8rem 0;
  }
  .comingSoonTitle {
    font-size: 3.5rem;
    padding-bottom: 1rem;
  }
  .ComingSoonFooter {
    font-size: 1rem;
  }
}

.animated-underline {
  text-decoration: none;
  color: $secondary-text;
  position: relative;
  transition: color 0.3s ease;
}

.animated-underline:hover {
  color: $primary-text;
}

.animated-underline::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: $secondary-text;
  transition: width 0.6s ease;
}

.animated-underline:hover::after,
.selected::after {
  width: 100%;
}

@media (max-width: 768px) {
  .comingsoonLlogo {
    width: 5rem;
  }
  .logoContainer {
    padding-bottom: 3rem;
  }
  .companyName {
    font-size: 2.5rem;
  }
  .comingSoonTitle {
    text-align: center;
    font-size: 2rem;
    padding-bottom: 0.5rem;
  }
  .comingSoonSubtitle {
    text-align: center;
    font-size: 1rem;
    padding-bottom: 0.2rem;
  }
  .ComingSoonFooter {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 10rem;
  }
  .footerlink {
    padding-bottom: 0.4rem;
  }
  .comingSoonFooterSection-start,
  .comingSoonFooterSection-center,
  .comingSoonFooterSection-end {
    width: 90vw;
    justify-content: start;
    align-items: flex-start;
  }
  .comingSoonFooterSection-end {
    flex-direction: row;
  }
  .languageSwitch {
    position: fixed;
  }
}
