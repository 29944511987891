@font-face {
  font-family: "futura-book";
  src: url("./Futura\ Book\ font.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "futura-light";
  src: url("./futura\ light\ bt.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "futura-bold";
  src: url("./futura\ medium\ bt.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "futura-medium-condensed";
  src: url("./futura\ medium\ condensed\ bt.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
