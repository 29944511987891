@import "../../styles.scss";

// Dashboard container

.dashboardContainer {
  width: 100vw;
  height: 100vh;
  background-color: $primary-background;
}

.routerContainer {
  padding: 0 1rem 0 0;
  display: flex;
  height: calc(100% - 80px);
  width: 100%;
  box-sizing: border-box;
}

// Navbar rules

.navbarWrapper {
  width: 100vw;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.4rem;
  box-sizing: border-box;
}

.navbarLogo {
  height: 35px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: $logo;
  margin-right: 0.8rem;
}

// Side bar rules

.navWrapper {
  height: 100%;
  width: 100px;
  display: flex;
  align-items: center;
}

.sidebarContainer {
  height: 85%;
  background-color: $white;
  width: 55px;
  position: absolute;
  left: 15px;
  border-radius: 15px;
  transition: 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.3rem;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  z-index: 90;
}

.sidebarContainer:hover {
  width: 210px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
}

.sidebarContainer:hover .iconContainer {
  width: 200px;
}

.iconContainer {
  height: 40px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 45px;
  margin-bottom: 6px;
  border-radius: 9px;
  transition: 0.3s;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 3;
  padding: 0 10.5px;
  overflow: visible;
  color: $secondary-text;
  text-decoration: none;
  -webkit-user-select: none;
}

.linkContent {
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.iconContainer:hover {
  color: $primary-text;
  background-color: $primary-background;
}

.active {
  color: $white;
  text-decoration: none;
}

.underline {
  position: absolute;
  background-color: rgb(81, 93, 181);
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 10px;
}

.icon,
.activeIcon {
  font-size: 1.5rem;
  transition: 0.3s;
}

.activeIcon:hover,
.active:hover {
  color: $white;
  transition: 0.3s ease-in-out;
}

.linkText {
  padding-left: 18px;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.sidebarContainer:hover .linkText {
  opacity: 1;
}
