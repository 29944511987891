@import "../../../../../styles.scss";

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 1rem 0;
}

.label {
  padding: 0.2rem 0;
  font-size: 0.9rem;
  width: 100%;
}

.input,
.error {
  padding: 8px;
  border-radius: 8px;
  width: 100%;
}

.input:focus,
.error:focus {
  border: 2px solid $primary-button-color;
  outline: $primary-background;
}

.input {
  border: 2px solid #ccc;
}

.error {
  border: 2px solid $secondary-button-color;
}

.errorMessage {
  font-size: 10px;
  color: $secondary-button-color;
  padding: 8px 0;
}

.helperText {
  font-size: 10px;
}

.splitInputs,
.double {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 1rem 0;
}

.doubleButton {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr) 100px;
  align-items: flex-end;
  justify-content: center;
}
.splitInputs {
  grid-template-rows: repeat(2, 1fr);
}

.dropppable {
  grid-row: span 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
}

.multiDroppable {
  width: 100%;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.splitSection {
  grid-row: span 1;
  padding: 0 0.2rem;
  height: 5rem;
}

.authorButton {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
  height: 50%;
  width: 80%;
  font-size: 1rem;
  color: $secondary-text;
  text-decoration: underline;
  transition: 0.3s;
  text-align: left;
}

.authorButton:hover {
  color: #000;
}

.imagesContainer {
  display: flex;
  flex-wrap: wrap;
}
