@import "../../../styles.scss";

.container {
  width: 100%;
  height: 100%;
}

.dropzone {
  width: 100%;
  height: 100%;
  transform: 0.3s;
  border: 2px dashed rgb(204, 204, 204);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.previewImage {
  width: 100%; /* Make the image fill the full width */
  height: 100%; /* Make the image fill the full height */
  max-height: 8rem;
  max-width: 8rem;
  object-fit: cover; /* Maintain aspect ratio and crop overflow */
  border-radius: 8px;
  padding: 4px;
  display: flex;
  align-items: center;
}

.previewContainer,
.currentPreviewContainer {
  position: relative; /* Needed for positioning child elements */
  height: 8rem;
  width: 18rem;
  max-width: 8rem; /* Ensure the container takes full width */
  overflow: hidden; /* Hide any overflow from the image */
}

.previewButton {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: $secondary-button-color;
  height: 1.7rem;
  width: 1.7rem;
  border-radius: 50%;
  color: $white;
  cursor: pointer;
  border: none;
}
.previewButton:hover {
  background-color: rgb(120, 15, 15);
}
