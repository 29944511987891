@import "../../../styles.scss";

.routerContainer {
  height: 100%;
  overflow: hidden;
  padding: 0;
}

.funcitonbarWrapper {
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  font-size: 0.9rem;
}

.start,
.center,
.end,
.iconContainer {
  display: flex;
  align-items: center;
}

.iconContainer {
  cursor: pointer;
}

.iconContainer {
  padding: 0.6rem;
  border-radius: 3px;
  transition: background-color 0.3s ease-in-out;
}

.iconContainer:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.start {
  justify-content: flex-start;
}
.center {
  justify-content: center;
}
.end {
  justify-content: flex-end;
}

.searchBar {
  background-color: $white;
  border: none;
  color: $secondary-text;
  padding: 0.6rem 0;
  width: 100%;
}

.searchBarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.6rem;
  width: 70%;
  border-radius: 5px;
  background-color: $white;
}

.searchBar:focus {
  outline: $secondary-text;
}

.icon {
  font-size: 1.2rem;
  cursor: pointer;
}

.table {
  display: grid;
  grid-template-rows: auto 1fr; /* Header e contenuto */
}

.header {
  display: grid;
  grid-template-columns: 80px 150px repeat(3, 1fr) 50px;
  font-weight: bold;
  font-weight: 400;
  font-size: 15px;
  padding: 10px 0;
  text-align: start;
  background-color: #f1f1f1;
  border-bottom: 1px solid #c3c3c3;
  margin-top: 1rem;
}

.content {
  display: grid;
}

.row,
.rowSelected {
  display: grid;
  grid-template-columns: 80px 150px repeat(3, 1fr) 50px;
  color: $secondary-text;
  text-overflow: ellipsis;
  align-items: center;

  white-space: nowrap;
  overflow: hidden;
  text-align: start;
  font-size: 14px;
  background-color: $white;
  border-bottom: 1px solid #dadada;
  cursor: pointer;
}

.row:hover,
.rowSelected {
  background-color: rgba(165, 172, 228, 0.2);
}

.cell,
.header-cell {
  text-align: left;
  font-size: 0.8rem;
}

.checkboxColumn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.customCheckbox {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #d9d9d9;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-in-out;
}

/* Checked state */
.customCheckbox:checked {
  background-color: #3036ba; /* Your primary color */
  border-color: #3036ba;
}

/* Create checkmark */
.customCheckbox:checked::after {
  content: "";
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
}

/* Hover state */
.customCheckbox:hover {
  border-color: #3036ba;
}

/* Focus state for accessibility */
.customCheckbox:focus {
  outline: none;
  box-shadow: 0 0 0 1px #d9d9d9;
}

.rowPreview {
  height: 30px;
  width: auto;
  border-radius: 2px;
}

.grabContainer {
  padding: 8px 0;
  background-color: rgb(237, 237, 237);
  color: $primary-button-color;
  width: 28px;
  border-radius: 5px;
  display: flex;
  align-content: center;
  justify-content: center;
  cursor: grab;
}

.row:hover .grabContainer {
  background-color: $white;
}

.settingContainer {
  background-color: $white;
  width: 100%;
  height: 85%;
  box-sizing: border-box;
  border-radius: 15px;
  overflow: scroll;
}

.sectionInline {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  align-items: flex-end;
}
