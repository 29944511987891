@import "./fonts/fonts.css";

body {
  margin: 0;
  font-family: "futura-light", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  box-sizing: border-box;
  letter-spacing: 0.1rem;
}
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent; /* Disable highlight on tap */
  user-select: none; /* For other browsers */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
