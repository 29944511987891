@import "../../styles.scss";
.container {
  background-color: $primary-background;
  height: 100vh;
  width: 100vw;
  @include centered;
}

.loginContainer,
.logoContainer {
  flex: 1;
  @include centered;
  flex-direction: column;
}

.loginContainer {
  height: 100vh;
}

.logoContainer {
  background-color: $white;
  height: 100vh;
}

.logo {
  height: 90px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: $logo;
}

.logoText {
  font-size: 65px;
  font-weight: 200;
  color: $primary-text;
}

.secondaryText {
  color: $secondary-text;
  font-size: 18px;
}

.button {
  font-size: 16px;
  border-radius: 4px;
  border: none;
  width: 100%;
  padding: 10px 0;
  background-color: $primary-button-color;
  color: $white;
  font-size: 20px;
  font-weight: 600;
  box-sizing: content-box;
  margin-top: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.button:hover {
  background-color: #13076a;
}

.button:disabled {
  background-color: $secondary-text;
}

.form {
  padding-bottom: 2rem;
  border-bottom: 1px solid $primary-border;
  flex-direction: column;
}

.secondaryButton {
  font-size: 14px;
  color: $secondary-text;
  cursor: pointer;
  padding-top: 1rem;
  transition: text-decoration 0.3s;
}

.secondaryButton:hover {
  text-decoration: underline $secondary-text;
}
